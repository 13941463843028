import '../Styles/ComoFunciona.css'
import img1 from "../imagenes/ComoFunciona/cf_corazon.svg"
import img2 from "../imagenes/ComoFunciona/cf_GastosCLINICA.svg"
import img3 from "../imagenes/ComoFunciona/cf_SistemaPAGO.svg"
import img4 from "../imagenes/ComoFunciona/cf_COBERTURA.svg"
import img6 from "../imagenes/ComoFunciona/acbici.png"
import ApiComoFunciona from './ApiComoFunciona'
import ApiComoFuncionaHospitalizado from './ApiComoFuncionaHospitalizado'
import ApiComoFuncionaExamenes from './ApiComoFuncionaExamenes'
import Aos from "aos"
import "aos/dist/aos.css"
import React from 'react'

const ComoFunciona = () => {
  React.useEffect(() => {
    Aos.init({duration: 2000});
      }, []);
    return (
      <div id="cf">
        <div className=" mt-4 cfdivprin">
          <br />
          <h1 className="titulos">SEGUROS MEDS</h1>
          <h6 className="subtitulos">
          Alivian el otro dolor, el de pagar
          </h6><br/>
          <section data-aos="fade-up" className="container">
            <ul
              className="nav nav-tabs justify-content-center"
              role="tablist"
              style={{ marginLeft: "0.8rem", marginRight: "0.8rem" }}
              id="nav-cf"
            >
              <li className="nav-item card" onClick={() => document.getElementById('subCoberturaPlan').textContent='plan'}    >
                <a
                  className="nav-link active cfcardtitulo"
                  data-toggle="tab"
                  href="#catastrofico"
                  id="cfcardtitulo1"
                >
                  <div>
                    <h5 className="cftitulos">Catastrófico</h5>
                  </div>
                </a>
              </li>
              <li className="nav-item card" onClick={() => document.getElementById('subCoberturaPlan').textContent='cobertura'} >
                <a
                  className="nav-link cfcardtitulo"
                  data-toggle="tab"
                  href="#complementario"
                  id="cfcardtitulo2"
                >
                  <div>
                    <h5 className="cftitulos">Oncológico</h5>
                  </div>
                </a>
              </li>
              <li className="nav-item card" onClick={() => document.getElementById('subCoberturaPlan').textContent='cobertura'} >
                <a
                  className="nav-link cfcardtitulo"
                  data-toggle="tab"
                  href="#examenes"
                  id="cfcardtitulo4"
                >
                  <div>
                    <h5 className="cftitulos">Complementario</h5>
                  </div>
                </a>
              </li>
              <li className="nav-item card" onClick={() => document.getElementById('subCoberturaPlan').textContent='cobertura'} >
                <a
                  className="nav-link cfcardtitulo"
                  data-toggle="tab"
                  href="#accidentes"
                  id="cfcardtitulo3"
                >
                  <div>
                    <h5 className="cftitulos">Accidentes</h5>
                  </div>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div id="catastrofico" className="container tab-pane active">
                <br />
                <div
                  className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                  id="cfcol"
                >
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={img1} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        Sientes un dolor en el estómago y te diriges a Clínica MEDS, en donde te diagnostican Pancreatitis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                      <div>
                        <img src={img2} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        El total de los gastos en la clínica es de $7.500.000 incluyendo honorarios médicos, insumos, hospitalización, exámenes y tratamientos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div>
                        <img src={img3} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body">
                        <ApiComoFunciona/>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div>
                        <img src={img4} className="img-fluid" id="cfimg4" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        Tendrás cobertura durante tres años por el mismo diagnóstico, desde el primer día en que se diagnostique la enfermedad.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ApiComoFuncionaHospitalizado/>
              <ApiComoFuncionaExamenes/>
              <div id="accidentes" className="container tab-pane fade">
                <br />
                <div
                  className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                  id="cfcol"
                >
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={img1} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body ">
                        <p>
                        Tienes un accidente de origen deportivo y vas a Clínica MEDS.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                      <div>
                        <img src={img2} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        Tendrás una indemnización de 5 UF por día de hospitalización, considerando más de 24 horas continuas en el establecimiento hospitalario, 
                        con un tope de 180 días por evento y un máximo de 2 eventos al año.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div>
                        <img src={img3} className="img-fluid" id="cfimg3" alt=""/>
                      </div>
                      <div className="card-body">
                      <p>
                        También podrás tener una indemnización por fractura de hueso de UF 2, con un tope de 2 eventos al año, excluyendo el hueso nasal y la ruptura de huesos derivada de osteoporosis.                      
                      </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div>
                        <img src={img4} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                          Además, podrás tener hasta un 100% de reembolso en gastos médicos por accidente hasta completar un capital asegurado de UF 25.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="subtitulos">Puede aplicar tope máximo según tramo de edad y plan contratado.</h6>              
              <br/>
            </div>
          </section>
        </div>
      </div>
    );
}

export default ComoFunciona

