import '../Styles/Inicio.css'
import logo from "../imagenes/Home/logo_meds.svg"
import banner from "../imagenes/Home/banner_meds_2560×667.jpg"
import bannerMovil from "../imagenes/Home/banner_meds_810x212.jpg"
import { Nav, Navbar } from 'react-bootstrap'

const Inicio = () => {

    return (
      <div id="inicio">
        <div className="card" id="barraenlace">
          <Navbar expand="lg" className="navbar fixed-top">
            <Navbar.Brand href="#">
              <div id="ilogo" className="animate__animated animate__bounce">
                <img src={logo} alt="clinica meds"/>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" className="me-2"/>
            <Navbar.Collapse id="navbarScroll" className="justify-content-end">
              <Nav
                className="me-5 my-2 my-lg-0 inav animate__animated animate__bounce"
                navbarScroll>
                <Nav.Link href="#sr">NUESTROS PLANES</Nav.Link>
                <Nav.Link href="#cf">¿CÓMO FUNCIONA?</Nav.Link>
                <Nav.Link href="#bc">BENEFICIOS</Nav.Link>
                <Nav.Link href="#tnp">CONSULTAS FRECUENTES</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        <div className="d-none d-sm-none d-md-block">
          <img src={banner} alt="banner" className="img-fluid"/>
        </div>
        <div className="d-md-none">
          <img src={bannerMovil} alt="banner" className="img-fluid"/>
        </div>
      </div>
    );
}

export default Inicio
